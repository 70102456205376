<template>
  <div style="position: relative">
    <Breadcrumb :Breadcrumb="BreadcrumbCon" />

    <div class="search">
      <el-select
        v-model="valueArea"
        filterable
        clearable
        placeholder="请选择区号(可搜索关键字)"
        style="width: 240px"
        v-if="internationType"
      >
        <el-option
          v-for="item in options"
          :key="item.name"
          :label="item.name + item.tel"
          :value="item.tel"
        >
        </el-option>
      </el-select>
      <el-input placeholder="请输入手机号" v-model="phone"></el-input>

      <div>
        <el-button type="primary" @click="searchList">搜索</el-button>
        <el-button type="info" @click="reset">重置</el-button>
      </div>
    </div>

    <div class="table_con">
      <el-table :data="tableData" border style="width: 100%" height="100%">
        <el-table-column type="index" label="序号" width="80" align="center">
          <template slot-scope="scope">
            <span>{{ scope.$index + (currentPage - 1) * pageSize + 1 }}</span>
          </template>
        </el-table-column>

        <el-table-column prop="userName" label="姓名"> </el-table-column>
        <el-table-column prop="phoneArea" label="区号"> </el-table-column>
        <el-table-column prop="phone" label="手机号"> </el-table-column>
        <el-table-column prop="procurementAuth" label="采购权限">
        </el-table-column>
        <el-table-column label="权限设置">
          <template slot-scope="scope">
            <el-button @click="handleSet(scope.row)">设置</el-button>
            <el-dialog
              title="权限设置"
              :visible.sync="dialogVisible"
              :append-to-body="true"
            >
              <div>姓名：{{ dialogUserName }}</div>
              <div>手机号：{{ dialogPhone }}</div>
              <div>
                采购权限：
                <el-radio v-model="radio" :label="true">开启</el-radio>
                <el-radio v-model="radio" :label="false">关闭</el-radio>
              </div>

              <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisibleUpdate = false"
                  >取 消</el-button
                >
                <el-button type="primary" @click="handleSetSubmit"
                  >确 定</el-button
                >
              </span>
            </el-dialog>
          </template>
        </el-table-column>
      </el-table>

      <Pagination
        v-show="pageLength >= 0"
        :total="pageLength"
        :limit.sync="pageSize"
        @pagination="handlePageChange"
        :page-sizes="pageSizes"
      />
    </div>
  </div>
</template>

<script>
import { reqUrl } from "@/api/apiUrl";
import { request } from "@/api/request";
import { hideLoading, showLoading } from "@/util/loading";
import { areaCodeData } from "@/util/areaCodeData";

import Breadcrumb from "@/components/BreadCrumb";
import Pagination from "@/components/Pagination";

export default {
  components: { Breadcrumb, Pagination },
  inject: ["reload"],

  data() {
    return {
      BreadcrumbCon: [
        {
          name: "行世界",
        },
        {
          name: "权限管理",
        },
      ], // 面包屑数据

      phone: "",

      dialogVisible: false,
      radio: false,
      dialogPhone: "",
      dialogUserName: "",

      options: [],
      valueArea: "",

      tableData: [],

      internationType: false,
      role: "",
      phoneAreaROLE: "",

      // 分页数据
      currentPage: 1, // 当前页
      pageLength: 0,
      pageSize: 10, //要传过去的数据 每页多少条数据
      pageSizes: [10, 20, 50, 100],
    };
  },

  mounted() {
    this.options = areaCodeData;
    this.role = localStorage.getItem("ROLE");

    var strNation = this.role.includes("ROLE_nation");
    if (strNation) {
      this.internationType = true;
    }
    this.phoneAreaROLE = localStorage.getItem("phoneArea");

    showLoading();
    const opt = {
      url: reqUrl.worldTravelSelectProcurementAuth,
      method: "POST",
      params: JSON.stringify({
        phoneArea: this.phoneAreaROLE,
      }),
      resFunc: (res) => {
        console.log(res.data);
        if (res.data.code != 0) {
          this.$notify.error({
            title: "错误",
            message: res.data.message,
          });
          hideLoading();
        } else if (res.data.code == 0) {
          this.tableData = res.data.data.list;
          this.pageLength = res.data.data.total;
          this.pageSize = res.data.data.pageSize;

          this.tableData.map((item) => {
            this.changeItem(item);
          });
          hideLoading();
        }
        hideLoading();
      },
      errFunc: (err) => {
        this.$notify.error({
          title: "错误",
          message: err,
        });
        hideLoading();
      },
    };
    request(opt);
  },

  methods: {
    searchList() {
      showLoading();
      const opt = {
        url: reqUrl.worldTravelSelectProcurementAuth,
        method: "POST",
        params: JSON.stringify({
          phone: this.phone,
          phoneArea: this.valueArea || this.phoneAreaROLE,
        }),
        resFunc: (res) => {
          console.log(res.data);
          if (res.data.code != 0) {
            this.$notify.error({
              title: "错误",
              message: res.data.message,
            });
            hideLoading();
          } else if (res.data.code == 0) {
            this.tableData = res.data.data.list;
            this.pageLength = res.data.data.total;
            this.pageSize = res.data.data.pageSize;

            this.tableData.map((item) => {
              this.changeItem(item);
            });
            hideLoading();
          }
          hideLoading();
        },
        errFunc: (err) => {
          this.$notify.error({
            title: "错误",
            message: err,
          });
          hideLoading();
        },
      };
      request(opt);
    },

    changeItem(item) {
      if (item.procurementAuth) {
        item.procurementAuth = "已开";
      } else {
        item.procurementAuth = "已关";
      }
    },

    handleSet(row) {
      this.dialogVisible = true;
      this.radio = row.procurementAuth == "已关" ? false : true;
      this.dialogPhone = row.phone;
      this.dialogUserName = row.userName;
    },

    handleSetSubmit() {
      showLoading();
      const opt = {
        url: reqUrl.worldTravelInsertProcurementAuth,
        method: "POST",
        params: JSON.stringify({
          phone: this.dialogPhone,
          phoneArea: this.valueArea || this.phoneAreaROLE,
          procurementAuth: this.radio,
        }),
        resFunc: (res) => {
          console.log(res.data);
          if (res.data.code != 0) {
            this.$notify.error({
              title: "错误",
              message: res.data.message,
            });
            hideLoading();
          } else if (res.data.code == 0) {
            this.$message({
              type: "success",
              message: "设置成功",
            });
            this.refresh();
            hideLoading();
          }
          hideLoading();
        },
        errFunc: (err) => {
          this.$notify.error({
            title: "错误",
            message: err,
          });
          hideLoading();
        },
      };
      request(opt);
    },

    handlePageChange(data) {
      showLoading();
      const opt = {
        url: reqUrl.worldTravelSelectProcurementAuth,
        method: "POST",
        params: JSON.stringify({
          phone: this.phone,
          phoneArea: this.valueArea || this.phoneAreaROLE,
          pageNum: data.pageIndex,
          pageSize: data.pageSize,
        }),
        resFunc: (res) => {
          console.log(res.data);
          if (res.data.code != 0) {
            this.$notify.error({
              title: "错误",
              message: res.data.message,
            });
            hideLoading();
          } else if (res.data.code == 0) {
            this.tableData = res.data.data.list;
            this.pageLength = res.data.data.total;
            this.pageSize = res.data.data.pageSize;
            this.currentPage = data.pageIndex;

            this.tableData.map((item) => {
              this.changeItem(item);
            });
            hideLoading();
          }
          hideLoading();
        },
        errFunc: (err) => {
          this.$notify.error({
            title: "错误",
            message: err,
          });
          hideLoading();
        },
      };
      request(opt);
    },

    reset() {
      this.refresh();
    },

    // 刷新
    refresh() {
      this.reload();
    },
  },
};
</script>

<style lang="scss" scoped>
.search {
  display: flex;

  .el-input {
    width: 300px;
  }

  .el-input,
  .el-select {
    margin-right: 10px;
  }
}
</style>
